import React from "react"

import { Link } from "gatsby"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import "./o-nas.scss"

import ImgCasablanca1 from "../images/casablanca-1.jpg"
import ImgCasablanca2 from "../images/casablanca-2.jpg"
import ImgCasablanca3 from "../images/casablanca-3.jpg"
import ImgCasablanca4 from "../images/casablanca-4.jpg"

import ImgTreneriPixa from "../images/treneri/jiri-pixa.jpg"

const ONas = () => (

	<Layout>
		<Seo title="O nás" description="O nás" />

		<div className="stk-background stk-background--onas"></div>

		<div className="stk-container stk-container--top-margin">
			<h1 className="stk-h1">STK Praha<br />sportovní taneční klub</h1>

			<p className="mt-4 mb-0">
				Pokud chcete tančit už dál nehledejte! Jsme nejstarší a&nbsp;nejskvělejší taneční klub v&nbsp;České&nbsp;republice.
				<br />Učíme: děti, juniory, mládež, dospělé, jednotlivce, PRO-AM, taneční kurzy, individuálně doma.
				<br /><strong>Jestliže k&nbsp;nám začnete chodit už se tance nikdy nezbavíte!</strong>
				<Link className="mt2 stk-submenutreneri__link stk-submenutreneri__link--pixa-sub" to="/treneri/jiri-pixa">
					<img className="stk-submenutreneri__image" alt="Jiří Pixa" src={ImgTreneriPixa} />
					<span className="stk-submenutreneri__sub">
						<strong>Jiří Pixa</strong><br />
						prezident STK Praha
					</span>
				</Link>
			</p>

			<Row className="mt-5 justify-content-md-center">
				<Col className="stk-onas__item" xs="12" lg="4" xl="4">
					<div className="stk-onas__numbers">1928</div>
					<div className="stk-onas__header">založení tanečního klubu</div>
					<div className="stk-onas__description">
						Taneční klub STK Praha byl založen v&nbsp;roce 1928. Současné vedení řídí klub od&nbsp;roku 1998.
					</div>
				</Col>
				<Col className="stk-onas__item" xs="12" lg="4" xl="4">
					<div className="stk-onas__numbers">128+</div>
					<div className="stk-onas__header">aktivních členů klubu</div>
					<div className="stk-onas__description">
						Náš taneční klub má zastoupení ve&nbsp;všech věkových a&nbsp;výkonnostních kategoriích. Od&nbsp;hobby, juniorů až&nbsp;po&nbsp;seniory či&nbsp;profesionály.
					</div>
				</Col>
				<Col className="stk-onas__item" xs="12" lg="4" xl="4">
					<div className="stk-onas__numbers">20+</div>
					<div className="stk-onas__header">mistrů České repupbliky</div>
					<div className="stk-onas__description">
						V historii mistrovství České&nbsp;republiky náš klub vybojoval více než 20&nbsp;titulů Mistra České&nbsp;republiky a&nbsp;bezpočet finálových umístění. Zejména v&nbsp;hlavních kategoriích a&nbsp;mistrovství družstev.
					</div>
				</Col>
			</Row>

			<Row className="stk-seminar__row mt-3">
				<Col xl="3">
					<h3 className="stk-h3">Taneční sály STK Praha</h3>
					<Link className="stk-button stk-button--small mt-2 mb-5" to="/rozvrh-tanecnich-salu">
						Aktuální rozvrh
					</Link>
				</Col>
				<Col xl="3">
					<p>
						<h3 className="stk-h3">Taneční sál - budova CASABLANCA</h3>
						Vinohradská 184, Praha-Vinohrady, 130&nbsp;00<br />
						<a className="stk-button stk-button--small mt-2 mb-3" href="https://goo.gl/maps/gviNHcdBLMo6dmwg7" target="_blank" rel="noreferrer">zobrazit na mapě</a>
					</p>
				</Col>
				<Col xl="3">
					<p>
						<h3 className="stk-h3">Bohemia Dance</h3>
						U&nbsp;Měšťanského pivovaru 934/4, Praha&nbsp;7 - Holešovice, 170&nbsp;00<br />
						<a className="stk-button stk-button--small mt-2 mb-3" href="https://goo.gl/maps/N9xjzfimkU9hR4ng8" target="_blank" rel="noreferrer">zobrazit na mapě</a>
					</p>
				</Col>
				<Col xl="3">
					<p>
						<h3 className="stk-h3">Taneční sál Místo tance</h3>
						Na Petřinách 82, Praha&nbsp;6, 162&nbsp;00<br />
						<a className="stk-button stk-button--small mt-2 mb-3" href="https://goo.gl/maps/jmPqFoJ1xi9hG7fd6" target="_blank" rel="noreferrer">zobrazit na mapě</a>
					</p>
				</Col>
			</Row>

			<h2 className="stk-h2 mt-5 mb-5">Taneční sál Casablanca - Praha&nbsp;3 - Vinohrady</h2>

			<Row>
				<Col sm="6" md="3">
					<img className="stk-onas__photo" src={ImgCasablanca4} alt="velký taneční sál STK Praha - Casablanca" />
				</Col>
				<Col sm="6" md="3">
					<img className="stk-onas__photo" src={ImgCasablanca3} alt="malý taneční sál STK Praha - Casablanca" />
				</Col>
				<Col sm="6" md="3">
					<img className="stk-onas__photo" src={ImgCasablanca2} alt="malý taneční sál STK Praha - Casablanca" />
				</Col>
				<Col sm="6" md="3">
					<img className="stk-onas__photo" src={ImgCasablanca1} alt="bar tanečního sálu STK Praha - Casablanca" />
				</Col>
			</Row>

			<h2 className="stk-h2 mt-5 mb-5">Výsledky nejlepších tanečních párů STK Praha</h2>

			<Row className="mb-5 pb-5">

				<Col xs="12" lg="4">

					<div className="stk-onas__item">
						<div className="stk-onas__numbers stk-onas__numbers--vysledek">2021</div>
						<div className="stk-onas__header">1. místo - MČR senior 2 latina</div>
						<div className="stk-onas__description stk-onas__description--vysledek">Macoun Martin - Lutovská Ivana</div>
					</div>

					<div className="stk-onas__item">
						<div className="stk-onas__numbers stk-onas__numbers--vysledek">2021</div>
						<div className="stk-onas__header">2. místo - MČR senior 1 latina</div>
						<div className="stk-onas__description stk-onas__description--vysledek">Klíma Daniel - Pšeničková Radka</div>
					</div>

					<div className="stk-onas__item">
						<div className="stk-onas__numbers stk-onas__numbers--vysledek">2019</div>
						<div className="stk-onas__header">1. místo - MČR senior 2 latina</div>
						<div className="stk-onas__description stk-onas__description--vysledek">Macoun Martin - Lutovská Ivana</div>
					</div>

					<div className="stk-onas__item">
						<div className="stk-onas__numbers stk-onas__numbers--vysledek">2016</div>
						<div className="stk-onas__header">2. místo - ME senior o35 latina</div>
						<div className="stk-onas__description stk-onas__description--vysledek">Macoun Martin - Boháčová Jitka</div>
					</div>

					<div className="stk-onas__item">
						<div className="stk-onas__numbers stk-onas__numbers--vysledek">2015</div>
						<div className="stk-onas__header">1. místo - MS senior o40 latina</div>
						<div className="stk-onas__description stk-onas__description--vysledek">Macoun Martin - Boháčová Jitka</div>
					</div>

					<div className="stk-onas__item">
						<div className="stk-onas__numbers stk-onas__numbers--vysledek">2015</div>
						<div className="stk-onas__header">1. místo - ME senior o35 latina</div>
						<div className="stk-onas__description stk-onas__description--vysledek">Macoun Martin - Boháčová Jitka</div>
					</div>

					<div className="stk-onas__item">
						<div className="stk-onas__numbers stk-onas__numbers--vysledek">2015</div>
						<div className="stk-onas__header">4. místo - mistrovství ČR latina</div>
						<div className="stk-onas__description stk-onas__description--vysledek">Hrstka Marek - Flašková Veronika</div>
					</div>

					<div className="stk-onas__item">
						<div className="stk-onas__numbers stk-onas__numbers--vysledek">2014</div>
						<div className="stk-onas__header">3. místo - mistrovství ČR latina</div>
						<div className="stk-onas__description stk-onas__description--vysledek">Dědík Marek - Chytková Libuška</div>
					</div>

					<div className="stk-onas__item">
						<div className="stk-onas__numbers stk-onas__numbers--vysledek">2014</div>
						<div className="stk-onas__header">2. místo - MS senior o30 latina</div>
						<div className="stk-onas__description stk-onas__description--vysledek">Macoun Martin - Boháčová Jitka</div>
					</div>

					<div className="stk-onas__item">
						<div className="stk-onas__numbers stk-onas__numbers--vysledek">2013</div>
						<div className="stk-onas__header">4. místo - mistrovství ČR latina</div>
						<div className="stk-onas__description stk-onas__description--vysledek">Dědík Marek - Chytková Libuška</div>
					</div>

					<div className="stk-onas__item">
						<div className="stk-onas__numbers stk-onas__numbers--vysledek">2013</div>
						<div className="stk-onas__header">1. místo - MČR senior 1 latina</div>
						<div className="stk-onas__description stk-onas__description--vysledek">Macoun Martin - Motlová Romana</div>
					</div>

					<div className="stk-onas__item">
						<div className="stk-onas__numbers stk-onas__numbers--vysledek">2012</div>
						<div className="stk-onas__header">4. místo - mistrovství ČR latina</div>
						<div className="stk-onas__description stk-onas__description--vysledek">Dvořáček Jan - Chytková Libuška</div>
					</div>

				</Col>

				<Col xs="12" lg="4">

				<div className="stk-onas__item">
						<div className="stk-onas__numbers stk-onas__numbers--vysledek">2012</div>
						<div className="stk-onas__header">1. místo - MČR senior 1 latina</div>
						<div className="stk-onas__description stk-onas__description--vysledek">Macoun Martin - Motlová Romana</div>
					</div>

					<div className="stk-onas__item">
						<div className="stk-onas__numbers stk-onas__numbers--vysledek">2011</div>
						<div className="stk-onas__header">5. místo - mistrovství ČR latina</div>
						<div className="stk-onas__description stk-onas__description--vysledek">Voborský Jan - Chytková Libuška</div>
					</div>

					<div className="stk-onas__item">
						<div className="stk-onas__numbers stk-onas__numbers--vysledek">2011</div>
						<div className="stk-onas__header">1. místo - MČR senior 1 latina</div>
						<div className="stk-onas__description stk-onas__description--vysledek">Macoun Martin - Motlová Romana</div>
					</div>

					<div className="stk-onas__item">
						<div className="stk-onas__numbers stk-onas__numbers--vysledek">2010</div>
						<div className="stk-onas__header">1. místo - MČR senior 1 latina</div>
						<div className="stk-onas__description stk-onas__description--vysledek">Macoun Martin - Motlová Romana</div>
					</div>

					<div className="stk-onas__item">
						<div className="stk-onas__numbers stk-onas__numbers--vysledek">2009</div>
						<div className="stk-onas__header">1. místo - mistrovství ČR latina</div>
						<div className="stk-onas__description stk-onas__description--vysledek">Kostovčík Michal - Zaitseva Albina</div>
					</div>

					<div className="stk-onas__item">
						<div className="stk-onas__numbers stk-onas__numbers--vysledek">2008</div>
						<div className="stk-onas__header">1. místo - mistrovství ČR latina</div>
						<div className="stk-onas__description stk-onas__description--vysledek">Kostovčík Michal - Zaitseva Albina</div>
					</div>

					<div className="stk-onas__item">
						<div className="stk-onas__numbers stk-onas__numbers--vysledek">2007</div>
						<div className="stk-onas__header">2. místo - mistrovství ČR latina</div>
						<div className="stk-onas__description stk-onas__description--vysledek">Kostovčík Michal - Chytková Libuše</div>
					</div>

					<div className="stk-onas__item">
						<div className="stk-onas__numbers stk-onas__numbers--vysledek">2002</div>
						<div className="stk-onas__header">2. místo - mistrovství ČR latina</div>
						<div className="stk-onas__description stk-onas__description--vysledek">Rezler Zdeněk - Barašina Olga</div>
					</div>

					<div className="stk-onas__item">
						<div className="stk-onas__numbers stk-onas__numbers--vysledek">2001</div>
						<div className="stk-onas__header">2. místo - mistrovství ČR 10 tanců</div>
						<div className="stk-onas__description stk-onas__description--vysledek">Mikyška Milan - Holubová Lenka</div>
					</div>

					<div className="stk-onas__item">
						<div className="stk-onas__numbers stk-onas__numbers--vysledek">2000</div>
						<div className="stk-onas__header">2. místo - mistrovství ČR 10 tanců</div>
						<div className="stk-onas__description stk-onas__description--vysledek">Mikyška Milan - Holubová Lenka</div>
					</div>

					<div className="stk-onas__item">
						<div className="stk-onas__numbers stk-onas__numbers--vysledek">1999</div>
						<div className="stk-onas__header">1. místo - mistrovství ČR standard</div>
						<div className="stk-onas__description stk-onas__description--vysledek">Gebert Marcel - Strakošová Lenka</div>
					</div>

					<div className="stk-onas__item">
						<div className="stk-onas__numbers stk-onas__numbers--vysledek">1998</div>
						<div className="stk-onas__header">1. místo - mistrovství ČR standard</div>
						<div className="stk-onas__description stk-onas__description--vysledek">Gebert Marcel - Strakošová Lenka</div>
					</div>

				</Col>

				<Col xs="12" lg="4">


					<div className="stk-onas__item">
						<div className="stk-onas__numbers stk-onas__numbers--vysledek">1997</div>
						<div className="stk-onas__header">1. místo - mistrovství ČR standard</div>
						<div className="stk-onas__description stk-onas__description--vysledek">Gebert Marcel - Strakošová Lenka</div>
					</div>

					<div className="stk-onas__item">
						<div className="stk-onas__numbers stk-onas__numbers--vysledek">1996</div>
						<div className="stk-onas__header">1. místo - mistrovství ČR standard</div>
						<div className="stk-onas__description stk-onas__description--vysledek">Gebert Marcel - Strakošová Lenka</div>
					</div>

					<div className="stk-onas__item">
						<div className="stk-onas__numbers stk-onas__numbers--vysledek">1995</div>
						<div className="stk-onas__header">1. místo - mistrovství ČR standard</div>
						<div className="stk-onas__description stk-onas__description--vysledek">Gebert Marcel - Strakošová Lenka</div>
					</div>
					<div className="stk-onas__item">
						<div className="stk-onas__numbers stk-onas__numbers--vysledek">1994</div>
						<div className="stk-onas__header">3. místo - mistrovství ČR standard</div>
						<div className="stk-onas__description stk-onas__description--vysledek">Gebert Marcel - Strakošová Lenka</div>
					</div>

					<div className="stk-onas__item">
						<div className="stk-onas__numbers stk-onas__numbers--vysledek">1993</div>
						<div className="stk-onas__header">1. místo - mistrovství ČR latina</div>
						<div className="stk-onas__description stk-onas__description--vysledek">Čížek Roman - Bartáková Alice</div>
					</div>

					<div className="stk-onas__item">
						<div className="stk-onas__numbers stk-onas__numbers--vysledek">1991</div>
						<div className="stk-onas__header">3. místo - mistrovství ČR standard</div>
						<div className="stk-onas__description stk-onas__description--vysledek">Filgas Rostislav - Filgasová Jana</div>
					</div>

					<div className="stk-onas__item">
						<div className="stk-onas__numbers stk-onas__numbers--vysledek">1991</div>
						<div className="stk-onas__header">1. místo - mistrovství ČR latina</div>
						<div className="stk-onas__description stk-onas__description--vysledek">Ostrůvka Radoslav - Kolářová Gabriela</div>
					</div>

					<div className="stk-onas__item">
						<div className="stk-onas__numbers stk-onas__numbers--vysledek">1990</div>
						<div className="stk-onas__header">1. místo - mistrovství ČR standard</div>
						<div className="stk-onas__description stk-onas__description--vysledek">Žmuran Antonín - Lejsková Ivana</div>
					</div>

					<div className="stk-onas__item">
						<div className="stk-onas__numbers stk-onas__numbers--vysledek">1990</div>
						<div className="stk-onas__header">1. místo - mistrovství ČR latina</div>
						<div className="stk-onas__description stk-onas__description--vysledek">Ostrůvka Radoslav - Kolářová Gabriela</div>
					</div>

					<div className="stk-onas__item">
						<div className="stk-onas__numbers stk-onas__numbers--vysledek">1989</div>
						<div className="stk-onas__header">3. místo - mistrovství ČR standard</div>
						<div className="stk-onas__description stk-onas__description--vysledek">Žmuran Antonín - Lejsková Ivana</div>
					</div>

					<div className="stk-onas__item">
						<div className="stk-onas__numbers stk-onas__numbers--vysledek">1989</div>
						<div className="stk-onas__header">2. místo - mistrovství ČR latina</div>
						<div className="stk-onas__description stk-onas__description--vysledek">Ostrůvka Radoslav - Kolářová Gabriela</div>
					</div>

				</Col>

			</Row>

		</div>

	</Layout>

)

export default ONas